@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("http://fonts.googleapis.com/css2?family=Peralta&display=swap");

@font-face {
  font-family: "LapsusPro-Bold";
  src: url("../public/font/LapsusPro-Bold.otf") format("truetype");
  font-style: normal;
}

.peralta-regular {
  font-family: "Peralta", serif;
  font-weight: 400;
  font-style: normal;
}

.lapsus {
  font-family: "LapsusPro-Bold", sans-serif;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.memory-game {
  /* width: 640px; */
  /* height: 640px; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  perspective: 1000px;
}

.memory-card {
  width: calc(25% - 10px);
  height: calc(33.333% - 10px);
  margin: 5px;
  position: relative;
  transform: scale(1);
  transform-style: preserve-3d;
  transition: transform 0.5s;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.memory-card:active {
  transform: scale(0.97);
  transition: transform 0.2s;
}

.memory-card.flip {
  transform: rotateY(180deg);
}

.front-face,
.back-face {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  background: #30336b;
  backface-visibility: hidden;
}

.front-face {
  transform: rotateY(180deg);
}

.home {
  background-image: url('../src/assets/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

.hero-title {
  font-family: 'Peralta', serif;
  font-size: 6rem !important;
  -webkit-text-stroke: 2px black;
}

.moon-light {
  font-family: "LapsusPro-Bold", serif !important;
}

.buy-button {
  font-family: 'Peralta', serif !important;
}